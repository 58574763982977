import React from 'react';
import { Container, Row } from 'reactstrap';
import { LateralMenu } from '../../components/LateralMenu/LateralMenu';
import './Home.css';
import { HeaderHome } from '../../components/HeaderHome/HeaderHome';
import { MenuContextProvider } from '../../contexts/MenuContext';
import { Outlet } from 'react-router-dom';

export function Home() {
  return (
    <MenuContextProvider>
      <Container className="containerParent" fluid>
        <HeaderHome />
        <Row className="rowContent">
          <LateralMenu />
          <Row className="containerChild">
            <Outlet />
          </Row>
        </Row>
      </Container>
    </MenuContextProvider>
  );
}

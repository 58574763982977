import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserAuth } from '../domain/userAuth';
import { decodeJWT } from 'did-jwt';

type Roles = {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isOperator: boolean;
};

export const AuthContext = createContext({
  accessToken: localStorage.getItem('accessToken') ?? '',
  setToken: (_accessToken: string) => {
    return;
  },
  isSuperAdmin: false,
  isAdmin: false,
  isOperator: false,
});

export const AuthContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [accessToken, setAccessToken] = useState<string>(
    localStorage.getItem('accessToken') ?? '',
  );

  const { Provider } = AuthContext;

  function setToken(accessToken: string) {
    setAccessToken(accessToken);
    localStorage.setItem('accessToken', accessToken);
  }

  function handleExpiration(isExpired: boolean) {
    if (!isExpired) {
      return;
    }
    localStorage.removeItem('accessToken');
    setAccessToken('');
  }

  function handleAccessToken() {
    const cachedAccessToken = localStorage.getItem('accessToken');
    if (cachedAccessToken) {
      const decodedToken = decodeJWT(cachedAccessToken);
      const expirationDate = decodedToken.payload.exp ?? 0;
      const isExpired = expirationDate * 1000 < Date.now();
      handleExpiration(isExpired);
    }
  }

  useEffect(() => {
    handleAccessToken();
    document.addEventListener('visibilitychange', handleAccessToken);
  }, []);

  const roles: Roles = useMemo(() => {
    if (!accessToken)
      return {
        isSuperAdmin: false,
        isAdmin: false,
        isOperator: false,
      };
    const user = new UserAuth(accessToken);
    return {
      isSuperAdmin: user.isSuperadmin(),
      isAdmin: user.isAdmin(),
      isOperator: user.isOperator(),
    };
  }, [accessToken]);

  return (
    <Provider
      value={{
        accessToken,
        setToken,
        ...roles,
      }}
    >
      {children}
    </Provider>
  );
};

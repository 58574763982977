import { useContext, useEffect, useMemo, useState } from 'react';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import { Entity } from '../../entity';
import { AuthContext } from '../../../contexts/AuthContext';
import { getEntities } from '../../../services/entities';
import { ACTION_NAME_OPTIONS } from './buildsConstants';
import { AuditTrailsContextType } from '../../../contexts/CredentialsProviders/Providers/AuditTrailsContext';

interface AuditTrailsFilterFormProps {
  context: AuditTrailsContextType;
}

function AuditTrailsFilterForm({ context }: AuditTrailsFilterFormProps) {
  const {
    filters,
    setFilters,
    loadCredentials,
    tableSpecs,
    setAdvancedFilterModal,
    isLoading,
  } = useContext(context);
  const [entities, setEntities] = useState<SelectInputOption[]>([
    { translationKey: 'filters.selectEntity', value: '' },
  ]);
  const cachedFilterState = useMemo(() => filters, []);

  const { isSuperAdmin } = useContext(AuthContext);

  const handleClose = () => {
    setFilters(cachedFilterState);
    setAdvancedFilterModal(false);
  };

  const applyFilter = async () => {
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
    setAdvancedFilterModal(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;

    if (name === 'entityId') {
      const selectedEntity = entities.find((entity) => entity.value === value);
      filters.entityName = selectedEntity?.translationKey || '';
    }

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    if (!isSuperAdmin) {
      return;
    }
    const entitiesResponse: Entity[] = await getEntities();
    const entitiesOptions = [
      entities[0],
      ...entitiesResponse.map(({ id, name }) => ({
        value: id,
        translationKey: name,
      })),
    ];
    setEntities(entitiesOptions);
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { action, entityId, issuanceId, startDate, endDate } = filters;
    isSuperAdmin &&
      formBuilder.addInput('select', entityId, 'entityId', handleChange, {
        selectInputOptions: entities,
        placeholder: entities[0].translationKey,
      });
    formBuilder.addInput('select', action, 'action', handleChange, {
      selectInputOptions: ACTION_NAME_OPTIONS,
      placeholder: ACTION_NAME_OPTIONS[0].translationKey,
    });
    formBuilder.addInput('text', issuanceId, 'issuanceId', handleChange, {
      placeholder: 'Issuance ID',
    });
    formBuilder.addInput(
      'datetime-local',
      startDate,
      'startDate',
      handleChange,
      { label: 'filters.startDate' },
    );
    formBuilder.addInput('datetime-local', endDate, 'endDate', handleChange, {
      label: 'filters.endDate',
      min: startDate,
    });
    formBuilder.addButton('actions.cancel', handleClose, {
      className: 'buttonCancel',
    });
    formBuilder.addButton('actions.filter', applyFilter, {
      disabled: isLoading,
      className: 'buttonFilter',
    });
    return formBuilder.getJSX();
  }, [entities, filters]);
}

export default AuditTrailsFilterForm;

import { createContext, PropsWithChildren } from 'react';
import { CredentialListView } from '../../../domain/credentialView';
import {
  CONTEXT_DEFAULT_STATE,
  CredentialsContext,
  CredentialsContextTypes,
} from '../CredentialsContext';
import CredentialsProviderCreator from '../CredentialsProviderCreator';

export interface CredentialsFilters {
  types: string;
  email: string;
  did: string;
  searchableText: string;
  status: string;
}

export const INITIAL_FILTERS: CredentialsFilters = {
  types: '',
  email: '',
  did: '',
  searchableText: '',
  status: '',
};

const AllCredentialsContext = createContext<
  CredentialsContext<CredentialListView, CredentialsFilters>
>({ ...CONTEXT_DEFAULT_STATE, filters: INITIAL_FILTERS });

AllCredentialsContext.displayName = CredentialsContextTypes.ALL_CREDENTIALS;

export type CredentialsContextType = typeof AllCredentialsContext;
export { AllCredentialsContext };

export function AllCredentialsContextProvider({
  children,
}: PropsWithChildren<any>) {
  return (
    <CredentialsProviderCreator
      context={AllCredentialsContext}
      initialFilters={INITIAL_FILTERS}
    >
      {children}
    </CredentialsProviderCreator>
  );
}

import React from 'react';
import {
  CredentialsContext,
  CredentialsContextTypes,
} from '../../contexts/CredentialsProviders/CredentialsContext';
import CredentialsFilterForm from './builds/CredentialsFilterForm';
import UserCredentialsFilterForm from './builds/UserCredentialsFilterForm';
import CredentialRequestsFilterForm from './builds/CredentialRequestsFilterForm';
import AuditTrailsFilterForm from './builds/AuditTrailsFilterForm';

interface FormControllerProps {
  context: React.Context<CredentialsContext<any, any>>;
}

function FormController({ context }: FormControllerProps) {
  if (context.displayName === CredentialsContextTypes.ALL_CREDENTIALS)
    return <CredentialsFilterForm context={context} />;
  if (context.displayName === CredentialsContextTypes.USER_CREDENTIALS)
    return <UserCredentialsFilterForm context={context} />;
  if (context.displayName === CredentialsContextTypes.CREDENTIAL_REQUESTS)
    return <CredentialRequestsFilterForm context={context} />;
  if (context.displayName === CredentialsContextTypes.AUDIT_TRAILS)
    return <AuditTrailsFilterForm context={context} />;
  return null;
}

export default FormController;

import React from 'react';
import { TableSpecs } from '../../pages/Credentials/Credentials';
import { CredentialType } from '../../domain/credentialTypes';
export interface CredentialsContext<CredentialsType, FiltersType> {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  credentials: CredentialsType[];
  setCredentials: React.Dispatch<React.SetStateAction<CredentialsType[]>>;
  tableSpecs: TableSpecs;
  setTableSpecs: React.Dispatch<React.SetStateAction<TableSpecs>>;
  credentialTypes: CredentialType[];
  advancedFilterModal: boolean;
  setAdvancedFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  loadSelectCredentialTypes: () => Promise<void>;
  loadCredentials: (
    _rowsPerPage: number,
    _page: number,
    _filterQuery?: string,
  ) => Promise<void>;
}

const INITIAL_CREDENTIAL_TYPES: CredentialType[] = [];
export const INITIAL_TABLE_SPECS: TableSpecs = {
  totalNumber: 0,
  firstPage: '',
  nextPage: '',
  previousPage: '',
  lastPage: '',
  currentPage: 1,
  tableSize: 10,
};

export const CONTEXT_DEFAULT_STATE = {
  isLoading: false,
  setIsLoading: () => null,
  setFilters: () => null,
  loadCredentials: async (
    _rowsPerPage: number,
    _page: number,
    _filterQuery?: string,
  ) => {
    return;
  },
  credentials: [],
  setCredentials: () => null,
  tableSpecs: INITIAL_TABLE_SPECS,
  setTableSpecs: () => null,
  credentialTypes: INITIAL_CREDENTIAL_TYPES,
  loadSelectCredentialTypes: () => {
    return Promise.resolve();
  },
  advancedFilterModal: false,
  setAdvancedFilterModal: () => null,
};

export enum CredentialsContextTypes {
  ALL_CREDENTIALS = 'Credentials',
  USER_CREDENTIALS = 'UserCredentials',
  CREDENTIAL_REQUESTS = 'CredentialRequests',
  AUDIT_TRAILS = 'AuditTrails',
}

export default {
  InvalidUserRolException: 'Invalid User Role.',
  RoleEntityException: 'Invalid User Role.',
  RoleIssuerException: 'Invalid User Role.',
  InvalidUuidFormatException: 'Invalid uuid format',
  InvalidISOFormatException: 'Invalid date format',
  InvalidEmailFormatException: 'Invalid email format',
  InvalidLevelOfAssuranceException: 'Level of Assurance not supported',
  InvalidFieldPathException: 'Invalid Field path',
  InvalidCredentialSubjectValue:
    'Credential subject property provided does not match with requested',
  InvalidCredentialSubjectProperty: 'Property not found in credential subject',
  DidNotFoundInJwt: 'DID not found in JWT',
  InternalServerCustomError: 'Internal Error',
  CredentialIdNotFoundException: 'Credential Order not found',
  CredentialOrderIdNotFoundException: 'Credential Order not found',
  CredentialOrdersByFilterNotFoundException:
    'There are no credentials that match your find',
  CredentialOrderNotFoundException: 'No credential order found',
  AuthServiceValidationException: 'Unauthorized Entity access',
  CredentialDuplicatedException:
    'The credential has already been issued previously',
  CredentialPayloadValidatorException:
    'Failed validating the credential payload',
  EntityDidNotValidException: 'Entity DID not valid',
  frontend_error_000: 'Error authenticating the User: ',
  frontend_error_001: 'Error creating the Credential Order: ',
  frontend_error_002: 'Error loading all credentials',
  frontend_error_003: 'Error issuing the Credential: ',
  frontend_error_004: 'Error getting the Credential Types: ',
  frontend_error_005: 'Error getting the Level Of Assurances: ',
  frontend_error_006: 'Error getting the Level Of Assurance type: ',
  frontend_error_007: 'Error getting Entities',
  frontend_error_008: 'Error resending the email',
  frontend_error_009: 'Error retrieving the credential request',
  login: {
    control:
      'Control and issue verifiable credentials at any time and from anywhere',
    with_vidchain:
      "With VIDidentity we're offering secure identity solutions that allow a more digital and sustainable world guaranteeing privacy, rights, and freedoms to people.",
    login_with: 'Login with VIDwallet',
    more_info: 'More info about VIDwallet',
    successful: 'The login was successful',
    failed: 'The login process failed',
    redirected: 'You will be redirected to the home screen.',
    try_again: 'Please try again or contact the admin of your site.',
    continueIssuance:
      'Click on the login button to continue with the issuance credential process',
    redirecting: 'Redirecting for authentication…',
    get_it: 'Get it free on your phone',
  },
  callback: {
    success: 'The credential issuance process was successful',
    mobile_wallet:
      'Credential issued. Open VIDwallet to receive the credential',
    go_wallet: 'Open VIDwallet to check the credential.',
    open_wallet: 'Open VIDwallet',
    failed: 'The credential issuance process failed',
    error_details: 'Error Details:',
  },
  actions: {
    cancel: 'Cancel',
    filter: 'Filter',
    accept: 'Accept',
    success: 'Success',
    confirm: 'Confirm',
    search: 'Search',
    create: 'Create',
    revoke: 'Revoke',
    resend: 'Resend',
    edit: 'Edit',
    close: 'Close',
    save: 'Save',
    approve: 'Approve',
    reject: 'Reject',
    sendToSign: 'Send to sign',
    ok: 'OK',
  },
  form: {
    fill: 'Fill in the form',
    receiverEmail: 'Email of the Receiver',
    identityVerification: 'Identity verification method',
    loadingCredential: 'Loading Credential type data ...',
    loadingIdentity: 'Loading Identity verification data ...',
    createNewUser: 'Create new user',
    fillfield: 'You must fill this field',
    provideValidEmail: 'You must provide a valid email',
    provideValidNumber: 'You must provide a valid personal number',
    provideValidURL: 'You must provide a valid URL',
    provideValidDate: 'You must provide a valid date',
    credentialCreated:
      'Credential order created successfully. The user will receive an email to get the credential',
    credentialF2FCreated:
      'Credential order created successfully. Please sign the document in device %%%%% to finish the process',
    credentialSubject: 'Credential Subject',
    language: 'Language',
    chooseLanguage: 'Choose a language...',
    credentialUpdated: 'Credential request updated successfully',
    userLanguage: 'User Language',
  },
  language: {
    spanish: 'Spanish',
    english: 'English',
  },
  filters: {
    any: 'Any attribute in the credential',
    credentialType: 'Credential Type',
    startDate: 'Start date',
    endDate: 'End date',
    actionName: 'Action Name',
    entityName: 'Entity Name',
    selectEntity: 'Select an entity',
    searchText: 'Search text',
    types: 'Type',
    status: 'Status',
  },
  table: {
    creationDate: 'Creation Date',
    notAvailable: 'N/A',
    sureRevoke: 'Are you sure you want to revoke this credential?',
    sureReject: 'Are you sure you want to reject this credential request?',
    agentName: 'Agent Name',
    date: 'Date',
    status: 'Status',
    viewDetails: 'View details',
    revokeCredential: 'Revoke credential',
    resendEmail: 'Resend email',
    ivmStatus: 'Identity Verification Method Status',
    expirationDate: 'Expiration date',
    rowsPerPage: 'Rows per page:',
    of: 'of',
    more: 'more than',
  },
  menu: {
    credentials: 'Credentials management',
    issueCredentials: 'Issue credentials',
    user: 'User management',
    auditTrails: 'Audit trails',
    credentialRequests: 'Credential requests',
  },
  details: {
    attachments: 'Attachments',
    auditTrailsDetails: 'Audit Trials detail',
    name: 'Name',
    eventName: 'Event name',
    operatorName: 'Agent name',
    entity: 'Entity',
    operator: 'Agent',
    role: 'Role',
    legalId: 'Legal ID',
    action: 'Action',
    payload: 'Payload',
    emailResentSuccess: 'Email has been resent successfully',
    revokeSuccess: 'Revocation request succeeded',
    rejectSuccess: 'Credential request rejected',
    credentialDetails: 'Credential Details',
    userDetails: 'User Details',
    requestDetails: 'Request Details',
    credentialRequestDetails: 'Credential Request',
    credentialOrderId: 'Credential Order ID',
    credentialIssuanceId: 'Credential Issuance ID',
    type: 'Credential type',
    types: 'Type',
    issuer: 'Issuer',
    holder: 'Holder',
    createdAt: 'Created At',
    issuedAt: 'Issued At',
    id: 'ID',
    test: 'Test',
    receiverEmail: 'Email of the receiver',
    identityVerificationMethod: 'Identity verification method',
    faceToFace: 'Face-to-face',
    next: 'Next',
    previous: 'Previous',
    stillNotSigned:
      'The document is still not signed. Sign the document in the device and then click Next button.',
    credentialIssuanceDocumentSignature:
      'Credential issuance document signature',
    selectDevice: 'Select a Device where to send the document to sign:',
    signedDocument: 'An email has been sent to the user to finish the process',
    docHasBeenSent:
      'Sign the document in the device and then click Next button.',
    docHasBeenRejected: 'Document Rejected',
    sent: 'Document sent',
    signed: 'Document signed',
    credentialOrderCreatedFromRequest:
      'Your credential request is approved. Go to credentials management.',
    credentialRequestApproved: 'Credential request approved',
  },
  credentialRequestApprovedModal: {},
  requestDetails: {
    credentialRequestId: 'Credential Request ID',
    type: 'Credential type',
    receiverEmail: 'Email of the receiver',
    requestedAt: 'Requested At',
    identityVerificationMethod: 'Identity verification method',
    F2F: 'Face-to-face',
    userDetails: 'User Details',
    EmailCredential: 'Email Credential',
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Full name',
    userLegalId: 'Legal ID',
  },
  status: {
    CREATED: 'Created',
    ISSUED: 'Issued',
    REVOKED: 'Revoked',
    REVOCATION_REQUESTED: 'Revoked requested',
    undefined: 'Undefined',
    REQUESTED: 'Requested',
    VERIFIED: 'Verified',
    UNVERIFIED: 'Unverified',
    ISSUE_REQUESTED: 'Issue Requested',
    REVOCATION_FAILED: 'Revocation failed',
  },
  requestStatus: {
    REQUESTED: 'Requested',
    APPROVED: 'Approved',
  },
  identityVerificationStatus: {
    CREATED: 'Created',
    STARTED: 'Started',
    DOCUMENT_REJECTED: 'Document rejected',
    DOCUMENT_SENT: 'Document sent',
    FINISHED: 'Finished',
  },
  auditTrailsActions: {
    UserLogin: 'User login',
    CredentialOrderCreated: 'Credential order created',
    ResendCredentialOrderEmail: 'Resend credential order email',
    CredentialHolderAuthenticated: 'Credential holder authenticated',
    CredentialIssued: 'Credential issued',
    CredentialRevocationRequested: 'Credential revocation requested',
    CredentialRevoked: 'Credential revoked',
    CredentialRequestCreated: 'Credential order request created',
    CredentialOrderRequestCreated: 'Credential order request created',
    CredentialRequestConfirmed: 'Credential request confirmed',
    CredentialRequestDocumentSigned: 'Credential request document signed',
    CredentialOrderRequestDeleted: 'Credential order request deleted',
    PresentationCreated: 'Presentation created',
  },
};

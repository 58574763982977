import { createContext, PropsWithChildren } from 'react';
import { UserCredentialListView } from '../../../domain/credentialView';
import {
  CONTEXT_DEFAULT_STATE,
  CredentialsContext,
  CredentialsContextTypes,
} from '../CredentialsContext';
import CredentialsProviderCreator from '../CredentialsProviderCreator';

export interface UserCredentialsFilters {
  entityId: string;
  entityName: string;
  email: string;
  did: string;
  searchableText: string;
  status: string;
}

const INITIAL_FILTERS: UserCredentialsFilters = {
  entityId: '',
  entityName: '',
  email: '',
  did: '',
  searchableText: '',
  status: '',
};

const UserCredentialsContext = createContext<
  CredentialsContext<UserCredentialListView, UserCredentialsFilters>
>({
  ...CONTEXT_DEFAULT_STATE,
  filters: INITIAL_FILTERS,
});
UserCredentialsContext.displayName = CredentialsContextTypes.USER_CREDENTIALS;

export type UserCredentialsContextType = typeof UserCredentialsContext;
export { UserCredentialsContext };

export function UserCredentialsContextProvider({
  children,
}: PropsWithChildren<any>) {
  return (
    <CredentialsProviderCreator
      context={UserCredentialsContext}
      initialFilters={INITIAL_FILTERS}
      credentialsContextType={CredentialsContextTypes.USER_CREDENTIALS}
    >
      {children}
    </CredentialsProviderCreator>
  );
}

import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axiosInstance from '../services/axiosInstance';

export const useAccessToken = (callAuthorizedEndpoint: () => Promise<void>) => {
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`;
    callAuthorizedEndpoint();
  }, [accessToken]);
};

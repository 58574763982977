import { createContext, PropsWithChildren } from 'react';
import { CredentialRequestsListView } from '../../../domain/credentialView';
import {
  CONTEXT_DEFAULT_STATE,
  CredentialsContext,
  CredentialsContextTypes,
} from '../CredentialsContext';
import CredentialsProviderCreator from '../CredentialsProviderCreator';

export interface CredentialRequestsFilters {
  email: string;
  types: string;
  searchableText: string;
  status: string;
}

const INITIAL_FILTERS: CredentialRequestsFilters = {
  email: '',
  types: '',
  searchableText: '',
  status: '',
};

const CredentialRequestsContext = createContext<
  CredentialsContext<CredentialRequestsListView, CredentialRequestsFilters>
>({ ...CONTEXT_DEFAULT_STATE, filters: INITIAL_FILTERS });
CredentialRequestsContext.displayName =
  CredentialsContextTypes.CREDENTIAL_REQUESTS;

export type CredentialRequestContextType = typeof CredentialRequestsContext;
export { CredentialRequestsContext };

export function CredentialRequestsContextProvider({
  children,
}: PropsWithChildren<any>) {
  return (
    <CredentialsProviderCreator
      context={CredentialRequestsContext}
      initialFilters={INITIAL_FILTERS}
      credentialsContextType={CredentialsContextTypes.CREDENTIAL_REQUESTS}
    >
      {children}
    </CredentialsProviderCreator>
  );
}

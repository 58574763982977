import { useContext, useMemo } from 'react';
import {
  CredentialRequestContextType,
  CredentialRequestsFilters,
} from '../../../contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import {
  credentialRequestsStatusOptions,
  placeholders,
} from './buildsConstants';

interface CredentialsRequestFilterFormProps {
  context: CredentialRequestContextType;
}
function CredentialRequestsFilterForm({
  context,
}: CredentialsRequestFilterFormProps) {
  const {
    filters,
    setFilters,
    credentialTypes,
    setAdvancedFilterModal,
    loadCredentials,
    tableSpecs,
    isLoading,
  } = useContext(context);
  const cachedFilterState = useMemo(() => filters, []);
  const credentialTypesOptions: SelectInputOption[] = [
    { translationKey: 'filters.credentialType', value: '' },
    ...credentialTypes.map(({ name }) => ({
      value: name,
      translationKey: name,
    })),
  ];

  const handleClose = () => {
    setFilters(cachedFilterState);
    setAdvancedFilterModal(false);
  };

  const applyFilter = async () => {
    const queryParams = convertToQueryString(filters);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
    setAdvancedFilterModal(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { status, types, ...textInputFilters } = filters;
    formBuilder.addInput('select', status, 'status', handleChange, {
      selectInputOptions: credentialRequestsStatusOptions,
      placeholder: credentialRequestsStatusOptions[0].translationKey,
    });
    formBuilder.addInput('select', types, 'types', handleChange, {
      selectInputOptions: credentialTypesOptions,
      placeholder: credentialTypesOptions[0].translationKey,
    });
    Object.keys(textInputFilters).map((key, _) => {
      formBuilder.addInput(
        'text',
        filters[key as keyof CredentialRequestsFilters],
        key,
        handleChange,
        {
          placeholder: placeholders[key],
        },
      );
    });
    formBuilder.addButton('actions.cancel', handleClose, {
      className: 'buttonCancel',
    });
    formBuilder.addButton('actions.filter', applyFilter, {
      disabled: isLoading,
      className: 'buttonFilter',
    });
    return formBuilder.getJSX();
  }, [filters]);
}

export default CredentialRequestsFilterForm;

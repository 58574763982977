import { useContext, useEffect, useMemo, useState } from 'react';
import { convertToQueryString } from '../../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { FormBuilder, SelectInputOption } from '../FormBuilder';
import { Entity } from '../../entity';
import { AuthContext } from '../../../contexts/AuthContext';
import { getEntities } from '../../../services/entities';
import {
  UserCredentialsContextType,
  UserCredentialsFilters,
} from '../../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { placeholders, statusOptions } from './buildsConstants';

interface UserCredentialsFilterFormProps {
  context: UserCredentialsContextType;
}

function UserCredentialsFilterForm({
  context,
}: UserCredentialsFilterFormProps) {
  const {
    filters,
    setFilters,
    loadCredentials,
    tableSpecs,
    setAdvancedFilterModal,
    isLoading,
  } = useContext(context);
  const [entities, setEntities] = useState<SelectInputOption[]>([
    { translationKey: 'filters.selectEntity', value: '' },
  ]);
  const cachedFilterState = useMemo(() => filters, []);

  const { isSuperAdmin } = useContext(AuthContext);

  const handleClose = () => {
    setFilters(cachedFilterState);
    setAdvancedFilterModal(false);
  };

  const applyFilter = async () => {
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
    setAdvancedFilterModal(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;

    if (name === 'entityId') {
      const selectedEntity = entities.find((entity) => entity.value === value);
      filters.entityName = selectedEntity?.translationKey || '';
    }

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    if (!isSuperAdmin) {
      return;
    }
    const entitiesResponse: Entity[] = await getEntities();
    const entitiesOptions = [
      entities[0],
      ...entitiesResponse.map(({ id, name }) => ({
        value: id,
        translationKey: name,
      })),
    ];
    setEntities(entitiesOptions);
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return useMemo(() => {
    const formBuilder = new FormBuilder();
    const { status, entityId, entityName, ...textInputFilters } = filters;
    formBuilder.addInput('select', filters.status, 'status', handleChange, {
      selectInputOptions: statusOptions,
      placeholder: statusOptions[0].translationKey,
    });
    isSuperAdmin &&
      formBuilder.addInput(
        'select',
        filters.entityId,
        'entityId',
        handleChange,
        {
          selectInputOptions: entities,
          placeholder: entities[0].translationKey,
        },
      );
    Object.keys(textInputFilters).map((key, _) => {
      formBuilder.addInput(
        'text',
        filters[key as keyof UserCredentialsFilters],
        key,
        handleChange,
        {
          placeholder: placeholders[key],
        },
      );
    });
    formBuilder.addButton('actions.cancel', handleClose, {
      className: 'buttonCancel',
    });
    formBuilder.addButton('actions.filter', applyFilter, {
      disabled: isLoading,
      className: 'buttonFilter',
    });
    return formBuilder.getJSX();
  }, [filters, entities]);
}

export default UserCredentialsFilterForm;

import React, { useContext } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import logo from '../../assets/logo.png';
import issueCredential from '../../assets/issueCredential.png';
import issueCredentialSelected from '../../assets/issueCredentialSelected.png';
import credentialManagement from '../../assets/credentialManagement.png';
import credentialManagementSelected from '../../assets/credentialsManagementSelected.png';
import roleManagement from '../../assets/roleManagement.png';
import roleManagementSelected from '../../assets/roleManagementSelected.png';
import credentialProfile from '../../assets/credentialProfile.png';
import credentialProfileSelected from '../../assets/credentialProfileSelected.png';
import './LateralMenu.css';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const LateralMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isMenuOpen, setIsOpen } = useContext(MenuContext);
  const { isSuperAdmin, isAdmin, isOperator } = useContext(AuthContext);

  return (
    <div className={`navDiv ${!isMenuOpen ? 'moved' : ''}`}>
      {isMenuOpen && <img src={logo} className="logoImage" />}
      <Nav vertical className="navLinks">
        <NavItem>
          <NavLink
            className={
              location.pathname === '/credentials'
                ? 'navlinkSelected'
                : 'navlink'
            }
          >
            <Link to={'/credentials'}>
              <>
                <img
                  src={
                    location.pathname === '/credentials'
                      ? credentialManagementSelected
                      : credentialManagement
                  }
                  className="iconLateral"
                />
                {isMenuOpen && t('menu.credentials')}
              </>
            </Link>
          </NavLink>
        </NavItem>
        {(isOperator || isAdmin) && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/new-credential'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/new-credential'}>
                <>
                  <img
                    src={
                      location.pathname === '/new-credential'
                        ? issueCredentialSelected
                        : issueCredential
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && t('menu.issueCredentials')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
        {(isOperator || isAdmin) && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/credential-requests'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/credential-requests'}>
                <>
                  <img
                    src={
                      location.pathname === '/credential-requests'
                        ? credentialManagementSelected
                        : credentialManagement
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && t('menu.credentialRequests')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
        {(isSuperAdmin || isAdmin) && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/user-credentials'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/user-credentials'}>
                <>
                  {' '}
                  <img
                    src={
                      location.pathname === '/user-credentials'
                        ? roleManagementSelected
                        : roleManagement
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && t('menu.user')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
        {(isAdmin || isSuperAdmin) && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/audit-trails'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/audit-trails'}>
                <>
                  {' '}
                  <img
                    src={
                      location.pathname === '/audit-trails'
                        ? credentialProfileSelected
                        : credentialProfile
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && t('menu.auditTrails')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <button
        className={`toggler ${!isMenuOpen ? 'open-toggler' : ''}`}
        onClick={() => setIsOpen(!isMenuOpen)}
      >
        {isMenuOpen ? '<<' : '>>'}
      </button>
    </div>
  );
};

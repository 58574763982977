import { createContext, PropsWithChildren } from 'react';
import {
  CONTEXT_DEFAULT_STATE,
  CredentialsContext,
  CredentialsContextTypes,
} from '../CredentialsContext';
import CredentialsProviderCreator from '../CredentialsProviderCreator';

export interface AuditTrailsView {
  action: string;
  date: string;
  entityName: string;
  eventId: string;
}

export interface AuditTrailsFilters {
  searchableText: string;
  action: string;
  startDate: string;
  endDate: string;
  entityName: string;
  entityId: string;
  issuanceId: string;
}

const INITIAL_FILTERS: AuditTrailsFilters = {
  searchableText: '',
  entityName: '',
  entityId: '',
  action: '',
  startDate: '',
  endDate: '',
  issuanceId: '',
};

const AuditTrailsContext = createContext<
  CredentialsContext<AuditTrailsView, AuditTrailsFilters>
>({ ...CONTEXT_DEFAULT_STATE, filters: INITIAL_FILTERS });

AuditTrailsContext.displayName = CredentialsContextTypes.AUDIT_TRAILS;

export type AuditTrailsContextType = typeof AuditTrailsContext;
export { AuditTrailsContext };

export function AuditTrailsContextProvider({
  children,
}: PropsWithChildren<any>) {
  return (
    <CredentialsProviderCreator
      context={AuditTrailsContext}
      initialFilters={INITIAL_FILTERS}
      credentialsContextType={CredentialsContextTypes.AUDIT_TRAILS}
    >
      {children}
    </CredentialsProviderCreator>
  );
}
